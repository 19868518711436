import React,{useState,useEffect,useContext} from 'react'
import './checkout.css'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import {useLocation,useNavigate} from 'react-router-dom'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import toast from 'react-hot-toast'
import { useDispatch,useSelector } from 'react-redux'
import {getAllCoupons} from '../../features/coupon/couponSlice'
import {createAnOrder} from '../../features/user/userSlice'
import { CartContext } from '../../context/CartContext';
const Checkout = () => {
  const [firstname,setFirstname]=useState("")
  const [lastname,setLastname]=useState("")
  const [success,setSuccess]=useState(false)
  const {usdCurrency,cartLength}=useContext(CartContext)
  const [email,setEmail]=useState("")

  const [phone,setPhone]=useState("")
  const [mobile,setMobile]=useState("")
  const [address,setAddress]=useState("")
  const [city,setCity]=useState("")
  const [state,setState]=useState("")
  const [pincode,setPincode]=useState("")
  const [verified,setVerified]=useState(false)

  const [cartItems, setCartItems] = useState([]);
const address1=JSON.parse(localStorage.getItem("address"))

const countryData = {
  "USD": { name: "United States", states: ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"] },
  "CAD": { name: "Canada", states: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan"] },
  "CHF": { name: "Switzerland", states: ["Aargau", "Appenzell Ausserrhoden", "Appenzell Innerrhoden", "Basel-Landschaft", "Basel-Stadt", "Bern", "Fribourg", "Geneva", "Glarus", "Graubünden", "Jura", "Luzern", "Neuchâtel", "Nidwalden", "Obwalden", "Schaffhausen", "Schwyz", "Solothurn", "St. Gallen", "Thurgau", "Uri", "Valais", "Vaud", "Zug", "Zürich"] },
  "CZK": { name: "Czech Republic", states: ["Prague", "Central Bohemian", "South Bohemian", "Plzeň", "Karlovy Vary", "Ústí nad Labem", "Liberec", "Hradec Králové", "Pardubice", "Vysočina", "South Moravian", "Olomouc", "Zlin", "Moravian-Silesian"] },
  "DKK": { name: "Denmark", states: ["Capital Region", "Central Denmark Region", "North Denmark Region", "Region Zealand", "South Denmark Region"] },
  "EUR": { name: "Eurozone", states: ["Austria", "Belgium", "Cyprus", "Estonia", "Finland", "France", "Germany", "Greece", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Portugal", "Slovakia", "Slovenia"] },
  "GBP": { name: "United Kingdom", states: ["England", "Scotland", "Wales", "Northern Ireland"] },
  "MXN": { name: "Mexico", states: ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Chiapas", "Chihuahua", "Coahuila", "Colima", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Mexico State", "Mexico City", "Michoacán", "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"] },
  "NOK": { name: "Norway", states: ["Agder", "Innlandet", "Møre og Romsdal", "Nordland", "Oslo", "Rogaland", "Troms og Finnmark", "Vestfold og Telemark", "Vestland"] },
  "PLN": { name: "Poland", states: ["Greater Poland", "Kuyavian-Pomeranian", "Lesser Poland", "Łódź", "Masovian", "Opole", "Podlaskie", "Pomeranian", "Silesian", "Subcarpathian", "Warmian-Masurian", "West Pomeranian"] },
  "SEK": { name: "Sweden", states: ["Blekinge", "Dalarna", "Gävleborg", "Gotland", "Halland", "Jämtland", "Jönköping", "Kalmar", "Kronoberg", "Norrbotten", "Skåne", "Stockholm", "Södermanland", "Uppsala", "Värmland", "Västerbotten", "Västernorrland", "Västmanland", "Västra Götaland"] }
};

const [selectedCountry, setSelectedCountry] = useState(usdCurrency?.code);
  const [states, setStates] = useState(countryData[usdCurrency?.code]?.states);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    if (countryCode) {
      setStates(countryData[countryCode]?.states || []);
    } else {
      setStates([]);
    }
  };
useEffect(()=>{
  if (!firstname && !lastname && !email && !address && !phone && !mobile && !city && !state && !pincode) {
      // Populate fields only if they're empty
      setFirstname(address1?.firstname || "")
      setLastname(address1?.lastname || "")
      setEmail(address1?.email || "")
      setAddress(address1?.address || "")
      setPhone(address1?.phone || "")
      setMobile(address1?.mobile || "")
      setCity(address1?.city || "")
      setState(address1?.state || "")
      setPincode(address1?.pincode || "")
  }
},[address1, firstname, lastname, email, address, phone, mobile, city, state, pincode])
  const location = useLocation();
  const navigate=useNavigate()
  const [orderNumber, setOrderNumber] = useState('');

  useEffect(() => {
    // Assume you fetch the order number from the backend or generate it here
    const generatedOrderNumber = generateOrderNumber(); // You would replace this with actual logic

    // Format the order number as desired (assuming it's received as a string)
    setOrderNumber(formatOrderNumber(generatedOrderNumber));
  }, []);

  // Function to generate a placeholder order number (for demonstration)
  const generateOrderNumber = () => {
    // Simulate generating a random number for demonstration purposes
    return Math.floor(Math.random() * 1000000); // Replace this with actual logic
  };

  // Function to format the order number (assuming it's received as a string or number)
  const formatOrderNumber = (number) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0'); // Month is zero-indexed
    const day = `${date.getDate()}`.padStart(2, '0');

    // Assuming number is a string or number received from backend
    const orderNumber = `${year}${month}${day}${String(number).padStart(3, '0')}`;

    return orderNumber;
  };
  const addProductToOrderLocalStorage = (product) => {
      const existingOrder = JSON.parse(localStorage.getItem("orders")) || [];
      const updatedOrder = [...existingOrder, product];
      localStorage.setItem("orders", JSON.stringify(updatedOrder));
    };

    const [verify,setVerify]=useState("SEND OTP")
    const [otp,setOtp]=useState()
    const [noneOtp,setNoneotp]=useState("none")
    const [paySpin,setPaySpin]=useState(false)
  const [totalAmount,setTotalAmount]=useState(null)
  const [orderType,setOrderType]=useState("Prepaid")
  const [shippingCost,setShippingCost]=useState(0)
  const [cartProductState,setCartProductState]=useState([])
  const [coupon,setCoupon]=useState("")
  const couponState=useSelector((state)=>state?.coupon?.coupon)
  useEffect(() => {
      // Retrieve cart items from localStorage
      const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(cartFromStorage);
      if(cartFromStorage?.length<1){
        navigate("/")
      }
  }, [cartLength]);
  

  const applyCoupon=()=>{
      couponState?.map((item)=>{
          if((item?.name.toLowerCase())===(coupon.toLowerCase())){
              if(item?.status==="active"){
                  if(item?.discounttype==="freeShip"){
                      setShippingCost(0)
                  }
                  if(item?.discounttype==="buyX"){
                      if(item?.minItem<=10){
                        if(item?.minItem>=cartItems?.length){
                          if(item?.discount?.includes("%")){
                              const percent=parseFloat(item?.discount)/100
                              setCouponAmount(percent*totalAmount)
                          }
                          else{
                              setCouponAmount(parseInt(item?.discount))
                          }
                        }
                        else{
                          toast.error("")
                        }
                      }
                      else{
                              if(item?.discount?.includes("%")){
                                  const percent=parseFloat(item?.discount)/100
                                  setCouponAmount(percent*totalAmount)
                              }
                              else{
                                  setCouponAmount(parseInt(item?.discount))
                              }
                            
                      }
                  }
                  if(item?.discounttype==="order"){
                      if(item?.discount?.includes("%")){
                          const percent=parseFloat(item?.discount)/100
                          setCouponAmount(percent*totalAmount)
                      }
                      else{
                          setCouponAmount(parseInt(item?.discount))
                      }
                  }
                  toast.success("Coupon Code Applied")
              }
          }
          // else{
          //     toast.error("Invalid Coupon")
          // }
      })
  }
  // const standardClick=()=>{
  //     setShippingCost(0)
  //     setOrderType("Prepaid")
  //     setCouponAmount((totalAmount)/10)
  // }

  useEffect (()=> {
      let sum=0;
      for(let index=0; index < cartItems?.length; index++){
          sum =sum+(Number(cartItems[index]?.quantity) *cartItems[index]?.price)
          setTotalAmount(sum)
      }
  },[cartItems,cartLength])
  const [couponAmount,setCouponAmount]=useState()
useEffect(()=>{
setCouponAmount(totalAmount/10)
},[totalAmount,cartLength])
  const finalAmount=shippingCost+totalAmount-couponAmount
  const dispatch=useDispatch();
 
  useEffect(()=>{
      dispatch(getAllCoupons())
  },[])


const completeOrder=()=>{
         localStorage.setItem("address",JSON.stringify({
          firstname:firstname,
          lastname:lastname,
          email:email,
          address:address,
          phone:phone,
          mobile:mobile,
          city:city,
          state:state,
          pincode:pincode,
         }))
         
      
  }


useEffect(()=>{
  let items=[]
  for (let index = 0; index < cartItems?.length; index++) {
      items.push({product:cartItems[index]?.product,quantity:cartItems[index].quantity,price:cartItems[index].price,color:cartItems[index].color,size:cartItems[index].size,sku:cartItems[index]?.product?.sku})
      
  }
  setCartProductState(items)
},[cartItems,cartLength])


useEffect(()=>{
  localStorage.setItem("temp",JSON.stringify(
      {
          shippingInfo:{
      firstname:firstname,
      lastname:lastname,
      email:email,
      phone:phone,
      address:address,
      city:city,
      state:state,
      country:selectedCountry,
      pincode:pincode,
      mobile:phone,},
      tag:"Voguemine",
      isPartial:false,
              orderItems:cartProductState,
              totalPrice:totalAmount,
              shippingCost:shippingCost,
              orderType:orderType,
              discount:couponAmount,
              finalAmount:finalAmount,
              success:success
  }))
},[firstname,lastname,email,phone,mobile,address,city,state,pincode,cartProductState,success])
useEffect(() => {
  return () => {
      if (location.pathname !== '/profile') {
          const addr = JSON.parse(localStorage.getItem("temp"));
          if (addr?.orderItems?.length > 0) {
                  if (addr?.shippingInfo?.firstname !== "" && addr?.shippingInfo?.phone !== "" && addr?.success===false) {
                      // dispatch(createAbondend(addr));
                  
              }
          }
      }
  };
}, [location]);
const [isRead,setIsread]=useState(false)
const initialTime = 30;

// State to keep track of the remaining time
const [timeLeft, setTimeLeft] = useState(initialTime);

  // Only set the interval if timeLeft is greater than 0
 

  const [intervalId, setIntervalId] = useState(null);

  
const sendOtps=async()=>{
  if(phone?.length<10){
      toast.info("Please Fill Correct Number")
  }
  else{
      setVerify("Verify")
  setNoneotp("block")
  setTimeLeft(initialTime); // Reset the countdown timer

  // Clear any existing interval (safety check)
  if (intervalId) clearInterval(intervalId);

  // Start a new countdown timer
  const id = setInterval(() => {
      setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
              clearInterval(id); // Stop the countdown timer when it reaches zero
              return 0;
          }
          return prevTime - 1;
      });
  }, 1000);
  setIntervalId(id);
  }

}

useEffect(()=>{
  const otps=JSON.parse(localStorage.getItem("verifiedOtp"))
  setVerified(otps?.verified)
},[])





useEffect(() => {
  // Cleanup interval on component unmount
  return () => {
      if (intervalId) clearInterval(intervalId);
  };
}, [intervalId]);
const formatTime = () => {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};



    const [age, setAge] = React.useState(10);

  const [orderID, setOrderID] = useState(false);
  const [captureError, setCaptureError] = useState(null);

  const createOrder = async () => {
  try {
      const response = await axios.post('https://voguemine2-kd7q.onrender.com/api/user/paypal/order', { amount:finalAmount,currency:usdCurrency?.code});
      return response.data.id; // PayPal order ID
    } catch (error) {
      console.error('Error creating PayPal order:', error);
    }
  };



  const onApprove = async (data) => {
    try {
      const response = await axios.post(`https://voguemine2-kd7q.onrender.com/api/user/paypal/order/${data.orderID}/capture`);
      await dispatch(createAnOrder({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, orderId: data?.orderID, shippingInfo: JSON.parse(localStorage.getItem("temp")).shippingInfo,tag:"Chicoline",orderNumber:orderNumber}))
     await window.fbq('track', 'Purchase', {
        content_name: 'Checkout',
        content_category: 'Page',
        content_ids: 'purchase',
        content_type: 'page',
        value: finalAmount,
        currency: usdCurrency?.code
    });
      await addProductToOrderLocalStorage({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, orderId: data?.orderID, shippingInfo: JSON.parse(localStorage.getItem("temp")).shippingInfo,tag:"Chicoline",orderNumber:orderNumber})
      localStorage.setItem("payInfo",JSON.stringify({pay:response.data}))
    } catch (error) {
      console.error('Error capturing order:', error);
      setCaptureError(error);
    }
  };


  return (
    <div className='checkouts'>
      <div className="left">
        <div className="scrolling">
            <div className="form">
            <div className="contact">
<p>Contact</p>
<TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        />
            </div>
            <div className="delivery">
<p>Delivery</p>
<FormControl fullWidth>
        <InputLabel id="country-label">Country</InputLabel>
        <Select
          labelId="country-label"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {Object.keys(countryData).map((countryCode) => (
            <MenuItem key={countryCode} value={countryCode}>
              {countryData[countryCode].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    <div className="name">
    <TextField
          label="First Name"
          type="text"
          value={firstname}
          onChange={(e)=>setFirstname(e.target.value)}
        />
        <TextField
          label="Last Name"
          type="text"
          value={lastname}
          onChange={(e)=>setLastname(e.target.value)}
        />
    </div>
    <TextField
          label="Address"
          type="text"
          value={address}
          onChange={(e)=>setAddress(e.target.value)}
        />

        <div className="city">
        <TextField
          label="City"
          type="text"
          value={city}
          onChange={(e)=>setCity(e.target.value)}
        />
        {selectedCountry && (
        <FormControl fullWidth>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            // Use state variable here if needed
            onChange={(e)=>setState(e.target.value)}
          >
            {states.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
        <TextField
          label="ZIP code"
          type="number"
          value={pincode}
          onChange={(e)=>setPincode(e.target.value)}
        />
        </div>
        <TextField
          label="Phone Number"
          type="number"
          value={phone}
          onChange={(e)=>setPhone(e.target.value)}
        />
            </div>
            </div>
            <div className="payment">
<p>Payment Option</p>
<div className='pay'>
<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png" alt="" />
<img src="https://cdn-icons-png.freepik.com/256/5290/5290109.png?semt=ais_hybrid" alt="" className="ok" />
</div>
            </div>

            <div className="complete">
                {/* <button>PAY NOW</button> */}
                <div className="App">
      {/* <h1>PayPal Integration</h1> */}
      <PayPalScriptProvider options={{ "client-id": "AeYjp8P8XcjjyOCF3qzLqT1_85X_SzZdhViV9GKvC0_iwrj8u2gV30KQxky9mcoQw7JYq0TsQfLB3GyR" }}>
        <PayPalButtons 
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
      {captureError && <p>Error capturing order: {captureError.message}</p>}
    </div>
            </div>
        </div>
      </div>
      <div className="right">
        <div className="prdts">
          {
            cartItems?.map((item,index)=>{
              return <div className="prdt">
              <div className="left">
                  <div className="img">
                      <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" />
                      <p>{item?.quantity}</p>
                  </div>
                  <div className="detail">
                      <p className="name">{item?.product?.title}</p>
                      <p className="color">Color: {item?.color}</p>
                      <p className="size">Size: {item?.size}</p>
                  </div>
              </div>
              <p className="amount"><span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} /><span>{item?.quantity*item?.price}</span></p>
          </div>
            })
          }
            
        </div>
        <div className="apply">
        <TextField
          label="Coupon Code"
          type="text"
          value={coupon}
          onChange={(e)=>setCoupon(e.target.value)}
        />
        <button onClick={applyCoupon}>Apply</button>
        </div>
        <div className="total">
            <div className="left">
                <p className="subtotal">Subtotal</p>
                <p className="discount">Discount</p>
                <p className="Shipping">Shipping</p>
                <p className="final">Total</p>
            </div>
            <div className="right">
            <p className="subtotal"><span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} /><span>{totalAmount}</span></p>
                <p className="discount">-<span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} /><span>{couponAmount}</span></p>
                <p className="Shipping"><span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} /><span>{shippingCost}</span></p>
                <p className="final"><span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} /><span>{finalAmount}</span></p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout
