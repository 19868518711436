import React,{useContext} from 'react'
import './orders.css'
import a2 from '../images/a2.jpg'
import { useLocation } from 'react-router-dom'
import { CartContext } from '../../context/CartContext';

const SingleOrder = () => {
  const {usdCurrency}=useContext(CartContext)

  const location=useLocation()
  const order=location.pathname.split("/")[2]
  const singleOrder=JSON.parse(localStorage.getItem("orders"))[order]
  return (
    <div className='sOrder'>
      <p className='num'>Order No. : {singleOrder?.orderNumber}</p>
      <div className="items">
        <div className="head">
            <p>Image</p>
            <p>Product</p>
            <p>Color</p>
            <p>Size</p>
            <p>Quantity</p>
            <p>Price</p>
        </div>
        {
          singleOrder?.orderItems?.map((item,index)=>{
            return <div className="item" key={index}>
            <img src={item?.product?.images[0]?.url} alt="" />
            <div className="details">
            <p className="name">{item?.product?.title}</p>
            <p className="color">{item?.color}</p>
            <p className="size">{item?.size}</p>
            <p className="qty">{item?.quantity}</p>
            <p className="amount">&#x24; {item?.price}</p>
            </div>
            <div className="details1">
            <p className="name"><span>Name: </span>{item?.product?.title}</p>
            <p className="color"><span>Color: </span>{item?.color}</p>
            <p className="size"><span>Size: </span>{item?.size}</p>
            <p className="qty"><span>Qty: </span>{item?.quantity}</p>
            <p className="amount"><span>Price: </span><span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {item?.price}</p>
            </div>
        </div>
          })
        }
            
      </div>
      <div className="info">
                <ul>
                    <li>Items</li>
                    <li>Subtotal</li>
                    <li>Discount</li>
                    <li>Total</li>
                </ul>
                <ul>
                <li>{singleOrder?.orderItems?.length}</li>
                                    <li><span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {singleOrder?.totalPrice}</li>
                                    <li>- <span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {singleOrder?.discount}</li>
                                    <li><span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {singleOrder?.finalAmount}</li>
                </ul>
            </div>
    </div>
  )
}

export default SingleOrder
