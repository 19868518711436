// Import statements
import img_amiri from '../pages/images/amiri.jpg';
import img_armani_exchange from '../pages/images/armani exchange.jpg';
import img_armani from '../pages/images/armani.jpg';
import img_balenciaga from '../pages/images/balenciaga.jpg';
import img_bally from '../pages/images/bally.jpg';
import img_balmain from '../pages/images/balmain.jpg';
import img_boss from '../pages/images/boss.jpg';
import img_bottega_veneta from '../pages/images/bottega veneta.jpg';
import img_burberry from '../pages/images/burberry.jpg';
import img_calvin_klein from '../pages/images/calvin klein.jpg';
import img_celine from '../pages/images/celine.jpg';
import img_chanel from '../pages/images/chanel.jpg';
import img_christian_dior from '../pages/images/christian dior.jpg';
import img_christian_louboutin from '../pages/images/christian louboutin.jpg';
import img_coach from '../pages/images/coach.jpg';
import img_d_g from '../pages/images/d&g.jpg';
import img_dior from '../pages/images/dior.jpg';
import img_dkny from '../pages/images/dkny.jpg';
import img_fendi from '../pages/images/fendi.jpg';
import img_ferragamo from '../pages/images/ferragamo.jpg';
import img_fred_berry from '../pages/images/fred berry.jpg';
import img_giorgio_armani from '../pages/images/giorgio armani.jpg';
import img_gucci from '../pages/images/gucci.jpg';
import img_hermes from '../pages/images/hermes.jpg';
// Skipping image_names.txt as it is not an image
import img_karl from '../pages/images/karl.jpg';
import img_kenzo from '../pages/images/kenzo.jpg';
import img_lanvin from '../pages/images/lanvin.jpg';
import img_lv from '../pages/images/lv.jpg';
import img_moncler from '../pages/images/moncler.jpg';
import img_moschino from '../pages/images/moschino.jpg';
import img_off_white from '../pages/images/off white.jpg';
import img_prada from '../pages/images/parada.jpg';
import img_salvatore_ferragamo from '../pages/images/salvatore ferragamo.jpg';
import img_stefano_ricci from '../pages/images/stefano ricci.jpg';
import img_the_north_face from '../pages/images/the north face.jpg';
import img_tods from '../pages/images/tods.jpg';
import img_valentino from '../pages/images/valentino.jpg';
import img_versace from '../pages/images/versace.jpg';
import img_victoria_secret from '../pages/images/victoria secret.jpg';
import img_zara from '../pages/images/zara.jpg';
import img_zegna from '../pages/images/zegna.jpg';

// Export the brands array
export const brands = [
    { name: 'amiri', view: img_amiri },
    { name: 'armani exchange', view: img_armani_exchange },
    { name: 'armani', view: img_armani },
    { name: 'balenciaga', view: img_balenciaga },
    { name: 'bally', view: img_bally },
    { name: 'balmain', view: img_balmain },
    { name: 'boss', view: img_boss },
    { name: 'bottega veneta', view: img_bottega_veneta },
    { name: 'burberry', view: img_burberry },
    { name: 'calvin klein', view: img_calvin_klein },
    { name: 'celine', view: img_celine },
    { name: 'chanel', view: img_chanel },
    { name: 'christian dior', view: img_christian_dior },
    { name: 'christian louboutin', view: img_christian_louboutin },
    { name: 'coach', view: img_coach },
    { name: 'd&g', view: img_d_g },
    { name: 'dior', view: img_dior },
    { name: 'dkny', view: img_dkny },
    { name: 'fendi', view: img_fendi },
    { name: 'ferragamo', view: img_ferragamo },
    { name: 'fred berry', view: img_fred_berry },
    { name: 'giorgio armani', view: img_giorgio_armani },
    { name: 'gucci', view: img_gucci },
    { name: 'hermes', view: img_hermes },
    { name: 'karl', view: img_karl },
    { name: 'kenzo', view: img_kenzo },
    { name: 'lanvin', view: img_lanvin },
    { name: 'lv', view: img_lv },
    { name: 'moncler', view: img_moncler },
    { name: 'moschino', view: img_moschino },
    { name: 'off white', view: img_off_white },
    { name: 'prada', view: img_prada },
    { name: 'salvatore ferragamo', view: img_salvatore_ferragamo },
    { name: 'stefano ricci', view: img_stefano_ricci },
    { name: 'the north face', view: img_the_north_face },
    { name: 'tods', view: img_tods },
    { name: 'valentino', view: img_valentino },
    { name: 'versace', view: img_versace },
    { name: 'victoria secret', view: img_victoria_secret },
    { name: 'zara', view: img_zara },
    { name: 'zegna', view: img_zegna }
];
