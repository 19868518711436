import './App.css';
import { useEffect, useState } from 'react';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import About from './pages/about/About';
import Products from './pages/allproducts/Products';
import Checkout from './pages/checkout/Checkout';
import Collection from './pages/collection/Collection';
import Home from './pages/home/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Thank from './pages/thankyou/Thank';
import Sproduct from './pages/singleproduct/Sproduct';
import axios from 'axios'
import Contact from './pages/contact/Contact';
import Search from './pages/allproducts/Search';
import Policy from './pages/policy/Policy';
import Orders from './pages/orders/Orders';
import SingleOrder from './pages/orders/SingleOrder';
import Tracking from './pages/tracking/Tracking';
import ScrollToTop from './components/ScrollToTop';
import Wishlist from './pages/wishlist/Wishlist';

function App() {
  
  return (
    <div className="App">
      <Router>
        <Header/>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/orders" element={<Orders/>}/>
          <Route path="/wishlist" element={<Wishlist/>}/>
          <Route path="/tracking/:id" element={<Tracking/>}/>
          <Route path="/orders/:id" element={<SingleOrder/>}/>
          <Route path="/policy/:id" element={<Policy/>}/>
          <Route path="/collections/:id/products/:id" element={<Products />}/>
          <Route path='/products' element={<Search/>}/>
          <Route path="/collections/Men" element={<Collection/>}/>
          <Route path="/collections/Women" element={<Collection/>}/>
          <Route path="/collections/Kids" element={<Collection/>}/>
          <Route path="/collections/Accessories" element={<Collection/>}/>

          <Route path="/checkout" element={<Checkout/>}/>
          <Route path="/product/:id" element={<Sproduct />}/>
          <Route path="/thankyou/:id" element={<Thank/>}/>
          <Route path='*' element={<Home/>}/>

        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
