import React,{useContext} from 'react'
import './thanks.css'
import {useNavigate} from 'react-router-dom'
import { CartContext } from '../../context/CartContext';

const Thank = () => {
  const {usdCurrency}=useContext(CartContext)
const navigate=useNavigate()
  const pay=JSON.parse(localStorage.getItem("payInfo"))
  return (
    <div className='thank'>
      <div className="thank-div">
        <h1>Thank You !</h1>
        <p>Thank you for visting <span>ChickOline</span> website</p>
  <p>Your Order of amount <span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }}></span><span>{pay?.pay?.purchase_units[0]?.payments?.captures[0]?.amount?.value}</span> is placed successfully</p>
        <p>Your Payment Id is <span>{pay.pay.id}</span></p>
        <p>You will recieved an email message sortly</p>
        <img src="https://i.pinimg.com/originals/d6/4f/60/d64f6038a5849a31279ce97358240d97.gif" alt="" />
        <h2>Check your Email</h2>
        <p>If you did't recieve any mail, contact <span>info@chickoline.com</span></p>
        <button onClick={()=>navigate("/")}><span>CONTINUE SHOPPING</span><span class="material-symbols-outlined">
arrow_right_alt
</span></button>
      </div>
    </div>
  )
}

export default Thank
