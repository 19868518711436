import React, { useState } from 'react'
import './footer.css'
import { useSelector } from 'react-redux'
import {useLocation,Link, useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'
const Footer = () => {
    const location=useLocation()
  const collectionState=useSelector((state)=>state?.collection?.collection)
  const navigate=useNavigate()
const [track,setTrack]=useState("")
const trackSearch=()=>{
    if(track===""){
        toast.error("Incorrect Tracking")
    }
    else{
        navigate(`/tracking/${track}`)
    }
}
    return (
        <div className='footer' style={{display:(location.pathname==="/checkout" || location.pathname.split("/")[1]==="thankyou")?"none":"block"}}>
            <div className="footer1">
            <div className="divs">
                    <p>Collections</p>
                    <ul>
                    <Link to={`/collections/Men`}><li>Mens</li></Link>
                <Link to={`/collections/Women`}><li>Womens</li></Link>
                <Link to={`/collections/Kids`}><li>Kids</li></Link>
                <Link to={`/collections/Accessories`}><li>Accessories</li></Link>
                    </ul>
                </div>
                <div className="divs">
                    <p>Useful Links</p>
                    <ul>
                        <li>Blogs</li>
                        <Link to='/contact'><li>Contact Us</li></Link>
                        <Link to='/about'><li>About Us</li></Link>
                        <Link to="/orders"><li>Orders</li></Link>
                    </ul>
                </div>
                <div className="divs">
                    <p>Policies</p>
                    <ul>
                        <Link to={`/policy/privacy-policy`}><li>Privacy Policy</li></Link>
                        <Link to={`/policy/shipping-policy`}><li>Shipping Policy</li></Link>
                        <Link to={`/policy/exchange-policy`}><li>Exchange Policy</li></Link>
                        <Link to={`/policy/terms-of-service`}><li>Terms of Service</li></Link>
                    </ul>
                </div>
                <div className="divs">
                    <p>Customers</p>
                    <ul>
                        <li>15,000+ Happy Customers</li>
                        <li>22,000+ Orders</li>
                        <div className="track">
                            <p>Track Your Order</p>
                            <div className="tracking">
                            <input type="text" name="" id="" placeholder='Enter Tracking Number' value={track} onChange={(e)=>setTrack(e.target.value)}/>
                            <button onClick={trackSearch}>Search</button>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <p className="logo">
                Chic'O'line
            </p>
            <p className="copy">© 2024, Chicoline</p>
        </div>
    )
}

export default Footer
