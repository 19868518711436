import { createContext, useState } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [current,setCurrent]=useState("USD")
  const currencies = [
    { code: 'USD', value: 83, htmlCode: '&#36;' }, // US Dollar
    { code: 'CAD', value: 60, htmlCode: '&#36;' },   // Canadian Dollar
    { code: 'CHF', value: 94, htmlCode: '&#67;&#72;&#70;' },   // Swiss Franc
    { code: 'CZK', value: 3.5, htmlCode: '&#75;&#269;' },   // Czech Koruna
    { code: 'DKK', value: 12, htmlCode: '&#107;&#114;' },   // Danish Krone
    { code: 'EUR', value: 90.5, htmlCode: '&#8364;' },  // Euro
    { code: 'GBP', value: 107.5, htmlCode: '&#163;' },  // British Pound Sterling
    { code: 'MXN', value: 4.5, htmlCode: '&#36;' },    // Mexican Peso
    { code: 'NOK', value: 7.5, htmlCode: '&#107;&#114;' },   // Norwegian Krone
    { code: 'PLN', value: 21, htmlCode: '&#122;&#322;' },   // Polish Zloty
    { code: 'SEK', value: 7.7, htmlCode: '&#107;&#114;' },   // Swedish Krona
];
const usdCurrency = currencies.find(currency => currency.code === current);

const changeCurrency=(item)=>{
  setCurrent(item)
}

  const [cartLength, setCartLength] = useState(0);
  const [wishLength,setWishLength]=useState(0)
  const [cartDrawOpen, setCartDrawOpen] = useState(false);
  const addToCarts = (item) => {
    setCartLength(item?.length + 1);
    setCartDrawOpen(true);
  };
  const goToCart=(val)=>{
    setCartDrawOpen(val)
  }
  const removeFromCart=(item)=>{
    setCartLength(item?.length-1)
  }
  const addToWishlist = (item) => {
    setWishLength(item?.length + 1);
  }; 
  const removeToWishlist = (item) => {
    setWishLength(item?.length - 1);
  }; 
  return (
    <CartContext.Provider
      value={{
        cartLength,
        cartDrawOpen,
        addToCarts,
        goToCart,
        removeFromCart,
        changeCurrency,
        usdCurrency,
        current,
        addToWishlist,
        wishLength,
        removeToWishlist
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };