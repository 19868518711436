import React from 'react'
import './contact.css'
import {Link} from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Contact = () => {
  return (
    <div className='contact'>
<div className="form">
<div className="other other1">
<div className="all">
<div className="phone">
<PhoneInTalkIcon className='icon'/>
<div>
    <p>Phone</p>
<Link>+91 9811363736</Link>

</div>
</div>
    <div className="mail">
    <EmailIcon className='icon'/>
    <div>
        <p>Email</p>
    <Link>info@customersupport.com</Link>

    </div>
    </div>
    <div className="whatsapp">
    <WhatsAppIcon className='icon'/>
    <div>
        <p>WhatsApp</p>
    <Link>+91 9811363736</Link>

    </div>
    </div>
</div>
</div>
    <div className="content">
        <div className="forms">
        <h1>Contact Us</h1>
        <div>
        <input type="text" name="" id="" placeholder='Enter Name'/>

        </div>
        <div>
        <input type="email" name="" id="" placeholder='Enter Email'/>

        </div>
        <div>
        <input type="number" name="" id="" placeholder='Enter Number'/>

        </div>
        <div>
        <textarea name="" id="" placeholder='Write your Query....'></textarea>

        </div>
        <div className='btns'>
        <button>Send Query</button>

        </div>
        </div>
    </div>
    <div className="other other2">
<div className="all">
<div className="phone">
<PhoneInTalkIcon className='icon'/>
<div>
    <p>Phone</p>
<Link>+91 9811363736</Link>

</div>
</div>
    <div className="mail">
    <EmailIcon className='icon'/>
    <div>
        <p>Email</p>
    <Link>info@customersupport.com</Link>

    </div>
    </div>
    <div className="whatsapp">
    <WhatsAppIcon className='icon'/>
    <div>
        <p>WhatsApp</p>
    <Link>+91 9811363736</Link>

    </div>
    </div>
</div>
</div>
</div>

    </div>
  )
}

export default Contact
