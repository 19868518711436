import React,{useContext,useState,useEffect} from 'react'
import './product.css'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {CartContext} from '../../context/CartContext'
const Product = (props) => {
  const location=useLocation()
  const {wishLength,addToWishlist,removeToWishlist}=useContext(CartContext)
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
const [red,setRed]=useState("")
const [wishlist,setWishlist]=useState([])
useEffect(()=>{
  setWishlist(JSON.parse(localStorage.getItem("wishlist")))
},[])
useEffect(()=>{
  setWishlist(JSON.parse(localStorage.getItem("wishlist")))
},[wishLength,location])
const addProductToWishlistLocalStorage = (product) => {
  const existingCart = JSON.parse(localStorage.getItem("wishlist")) || [];
  const updatedCart = [...existingCart, product];
  localStorage.setItem("wishlist", JSON.stringify(updatedCart));
};
useEffect(()=>{
  wishlist?.map((item)=>{
    if(item?._id===props?.info?._id){
      setRed("h")
    }
  })
},[wishlist,wishLength,props?.info])
const addToWish=async(data)=>{
  if(data && (data===props?.info?._id)){
    await addProductToWishlistLocalStorage(props?.info)
    addToWishlist(wishlist)
    setRed("h")

  }
}
const removeFromWish=(data)=>{
const updatedWishlist=wishlist?.filter(item=>{
  return !(item?._id===data)
}
)
setRed("")

localStorage.setItem("wishlist",JSON.stringify(updatedWishlist))
setWishlist(updatedWishlist)
removeToWishlist(wishlist)

}
const [sold,setSold]=useState("none")

useEffect(() => {
  if (props?.info?.variants) {
    const totalQuantity = props?.info?.variants.reduce((total, item) => total + item.quantity, 0);
    if (totalQuantity === 0) {
      setSold("flex");
    } else {
      setSold("none");
    }
  }
}, [props?.info?.variants]);



  return (
    <div className='product product-sold'>
      {
                  red===""?                <p className="wish-icon" onClick={(e)=>{addToWish(props?.info?._id)}}><FavoriteBorderOutlinedIcon className="cart-icon"/></p>
:
<p className="wish-icon" onClick={(e)=>{removeFromWish(props?.info?._id)}}><FavoriteIcon className="cart-icon" style={{color:'red'}}/></p>

                }

                <Link to={`/product/${props?.info?.handle}`}>
                <div className="sold" style={{display:sold}}>
                  <p>SOLD OUT</p>
                </div>
                </Link>
      <div className="top">
        <Link to={`/product/${props?.info?.handle}`}>
        <img src={modifyCloudinaryUrl(props?.info?.images[0]?.url)} alt="" />
        </Link>
        
        
      </div>
      <Link to={`/product/${props?.info?.handle}`}>
      <div className="bottom">
        <p className="name">{props?.info?.title}</p>
        <Stack spacing={1} className='star'>
      <Rating name="size-small" value={5} size="medium" readOnly/>
    </Stack>        
    <p className="price">
  <span dangerouslySetInnerHTML={{ __html: props?.current?.htmlCode }} />
  <span className='net'>{parseInt(props?.info?.price / props?.current?.value)}</span>
</p>      </div>
      </Link>
    </div>
  )
}

export default Product
