import React from 'react'
import './home.css'
import Product from '../../components/product/Product'
import video from '../images/video1.mp4'
import video1 from '../images/video3.mp4'
import video2 from '../images/video2.mp4'
import video3 from '../images/video4.mp4'
import {useSelector} from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {brands} from '../brands'
const Home = () => {

const navigate=useNavigate()
  const brandClick=(brand)=>{
      localStorage.setItem("search",JSON.stringify({
        mysearch:brand
      }))
        navigate("/products")
  }
  return (
    <div className='home'>
      <div className="mains">
        <div className="mens parent">
        <video className='videoTag' playsInline autoPlay loop muted>
    <source src={video} type='video/mp4' className='video'/>
</video>
            {/* <img src="https://res.cloudinary.com/dqh6bd766/image/upload/c_limit,h_1000,f_auto,q_auto/v1719309536/qp3ladym5reossyjavdw.jpg" alt="" /> */}
            <div className="detail">
              <p>MENS</p>
              <Link to={`/collections/Men`}><button>BUY NOW</button></Link>
            </div>
        </div>
        <div className="women parent">
        <video className='videoTag' playsInline autoPlay loop muted>
    <source src={video1} type='video/mp4' className='video'/>
</video>
            {/* <img src="https://res.cloudinary.com/dqh6bd766/image/upload/c_limit,h_1000,f_auto,q_auto/v1712317331/ktksofu0jxaydcfkb8ic.webp" alt="" /> */}
            <div className="detail">
              <p>WOMENS</p>
              <Link to={`/collections/Women`}><button>BUY NOW</button></Link>
            </div>
        </div>
        <div className="kids parent">
        <video className='videoTag' playsInline autoPlay loop muted>
    <source src={video2} type='video/mp4' className='video'/>
</video>
            {/* <img src="https://res.cloudinary.com/dqh6bd766/image/upload/c_limit,h_1000,f_auto,q_auto/v1712143276/v0itg81qoof3xxlytf2q.jpg" alt="" /> */}
            <div className="detail">
              <p>KIDS</p>
              <Link to={`/collections/Kids`}><button>BUY NOW</button></Link>
            </div>
        </div>
        <div className="accessories parent">
        <video className='videoTag' playsInline autoPlay loop muted>
    <source src={video3} type='video/mp4' className='video'/>
</video>
            {/* <img src="https://res.cloudinary.com/dqh6bd766/image/upload/c_limit,h_1000,f_auto,q_auto/v1717170207/yibupenwm4dtimsuer0j.jpg" alt="" /> */}
            <div className="detail">
              <p>ACCESSORIES</p>
              <Link to={`/collections/Accessories`}><button>BUY NOW</button></Link>
            </div>
        </div>
      </div>
      <div className="brands">
        <p className='main-heading'>OUR FEATURED BRANDS</p>
        <div className="list">
          {
            brands?.map((item,index)=>{
              return <div className="brand" key={index} onClick={(e)=>brandClick(item?.name)}>
              <img src={item?.view} alt={item?.name} />
            </div>
            })
          }
          
        </div>
      </div>




    </div>
  )
}

export default Home
