import React,{useEffect, useState} from 'react'
import './collection.css'
import {useLocation,Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getsingleCollection} from '../../features/collection/collectionSlice'
import loadingImg from '../images/loading.gif'
const Collection = () => {
  const dispatch=useDispatch()
  const collectionState=useSelector((state)=>state?.collection?.singlecollection)
  const location = useLocation();
  const getCollectionId = location.pathname.split("/")[2];
useEffect(()=>{
  dispatch(getsingleCollection({getCollectionId}))
},[dispatch,getCollectionId])

useEffect(() => {
  if (collectionState?.metaTitle!=="") {
      document.title = collectionState?.metaTitle;
  }
  else{
    document.title =collectionState?.title;
  }

}, [collectionState?.metaTitle,collectionState?.title]);
useEffect(() => {
if (collectionState?.metaDesc!=="") {
    document.querySelector('meta[name="description"]').setAttribute('content',collectionState?.metaDesc);
}
else{
  document.querySelector('meta[name="description"]').setAttribute('content',document.createElement('div').innerHTML = collectionState?.title );
}

}, [collectionState?.metaDesc,collectionState?.title]);
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};

const [load,setLoad]=useState(true)


useEffect(()=>{
setLoad(true)
},[getCollectionId])

useEffect(()=>{
  if(collectionState?.name!==""){
    setTimeout(()=>{
      setLoad(false)
    },1000)
  }
},[collectionState])

  return (
    <div className='collections'>
      {
        load?
        <div className="loader">
          <div className="loading">
          <img src={loadingImg} alt="" />

          </div>
        </div>
        :
        <div>
        <div className="collection-banner" style={{backgroundImage:`url(${modifyCloudinaryUrl(collectionState?.banner[0]?.url)})`}}>
          <p className="heading">Collections</p>
          <p className="path"><span>Home</span><span>-</span><span>{collectionState?.name}</span></p>
        </div>
        <div className="collection-list">
          {
              collectionState?.collections?.map((item,index)=>{
                  return <div className="collection" key={index}>
                  <img src={modifyCloudinaryUrl(item?.img[0]?.url)} alt="" />
                  <p className="name">{item?.cname}</p>
                  <Link to={`/collections/${collectionState?.name}/products/${item?.url}`}><button>SHOP THE COLLECTION</button></Link>
              </div>
              })
          }
        </div>
        </div>
      }
     
      
    </div>
  )
}

export default Collection
