import React, { useState,useEffect,useContext } from 'react'
import './header.css'
import {useLocation,Link, useNavigate} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import { getAllCollection } from '../../features/collection/collectionSlice';
import { CartContext } from '../../context/CartContext';
import {toast} from 'react-hot-toast'
const Header = () => {
  const {cartLength,goToCart,cartDrawOpen,removeFromCart,usdCurrency,changeCurrency,current,wishLength}=useContext(CartContext)
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const collectionState=useSelector((state)=>state?.collection?.collection)
  useEffect(()=>{
    dispatch(getAllCollection())
  },[dispatch])
const location=useLocation()
const [menu,setMenu]=useState("-100%")
const [carts,setCarts]=useState(false)
const openMenu=()=>{
    setMenu("0")
}
const closeMenu=()=>{
    setMenu("-100%")
}
const cartOpen=()=>{
  setCarts(true)
}
const cartHide=()=>{
  setCarts(false)
  goToCart(false)
  window.fbq('track', 'InitiateCheckout', {
    content_name:`initiate checkout`,
    content_category:`checkout`,
    content_ids:`initiate checkout`,
    content_type: 'product',
    value:`${totalAmount}`,
    currency: usdCurrency?.code
});

}
const cartHide1=()=>{
  setCarts(false)
  goToCart(false)
}
useEffect(()=>{
if(cartDrawOpen===true){
  setCarts(true)
}
},[cartDrawOpen])
const [showHeader, setShowHeader] = useState(true);
const [lastScrollTop, setLastScrollTop] = useState(0);
useEffect(() => {
 if(location.pathname.split("/")[1]!=="collections"){
  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
 }
}, [lastScrollTop,location]);

const [state, setState] = React.useState({
  top: false,
  left: false,
  bottom: false,
  right: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setState({ ...state, [anchor]: open });
};
const [cartItems, setCartItems] = useState([]);

const cart=JSON.parse(localStorage.getItem('cart'))
const wishlist=JSON.parse(localStorage.getItem('wishlist'))

useEffect(()=>{
  toggleDrawer("right",true)
},[])

    const [totalAmount,setTotalAmount]=useState(null)
    useEffect(() => {
        // Retrieve cart items from localStorage
        const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(cartFromStorage);
    }, []);
    useEffect(() => {
      // Retrieve cart items from localStorage
      const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(cartFromStorage);
      setCarts(true)
  }, [cart?.length]);

    const handleQuantityChange = (productId, color, size, changeType) => {
      const updatedCartItems = cart?.map(item => {
          if (item?.productId === productId && item?.color === color && item?.size === size) {
              const variant = item?.product?.variants.find(variant => variant?.color === color && variant?.size === size);
              let newQuantity = changeType === 'increment' ? item?.quantity + 1 : item?.quantity - 1;
  
              if (newQuantity > 0 && newQuantity <= variant?.quantity) {
                  return {
                      ...item,
                      quantity: newQuantity
                  };
              } else {
                  return { ...item };
              }
          }
          return item;
      });
  
      localStorage.setItem('cart', JSON.stringify(updatedCartItems));
      setCartItems(updatedCartItems);
  };


    const removeFromCartAndUpdate = (productIdToRemove, colorToRemove, sizeToRemove) => {
        // Filter out the item to remove based on productId, color, and size
        const updatedCartItems = cartItems.filter(item => {
            return !(item.productId === productIdToRemove && item.color === colorToRemove && item.size === sizeToRemove);
        });

        // Update localStorage with the updated cart items
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
toast.success("Cart Updated")
removeFromCart()
        // Update state to reflect the changes
        setCartItems(updatedCartItems);
    };
useEffect (()=> {
    let sum=0;
    for(let index=0; index < cartItems?.length; index++){
        sum =sum+(Number(cartItems[index]?.quantity) *cartItems[index]?.price)
        setTotalAmount(sum)
    }
    if(cartItems?.length<1){
      setCarts(false)
    }
},[cartItems])

const [searchValue,setSearchValue]=useState("")


const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter' && searchValue!=="") {   
    localStorage.setItem("search",JSON.stringify({
      mysearch:searchValue
    }))
    window.fbq('track', 'Search', {
      content_name: searchValue,
      content_category: 'Page',
      content_ids: 'purchase',
      content_type: 'page',
      value: 'search',
      currency: usdCurrency?.code
  });
      navigate(`/products`)
      setSearchValue("")
      setSearchOpen("-100%")
  }
  
};
const searchClick=()=>{
  if(searchValue!==""){
    localStorage.setItem("search",JSON.stringify({
      mysearch:searchValue
    }))
    window.fbq('track', 'Search', {
      content_name: searchValue,
      content_category: 'Page',
      content_ids: 'purchase',
      content_type: 'page',
      value: 'search',
      currency: usdCurrency?.code
  });
    navigate(`/products`)
    setSearchValue("")
    setSearchOpen("-100%")
  }
}

const [searchOpen,setSearchOpen]=useState("-100%")
const openSearch=()=>{
  setSearchOpen("0")
  setCarts(false)
  setMenu("-100%")
}
const closeSearch=()=>{
  setSearchOpen("-100%")
}

  return (
    <>
    <div className={`header ${showHeader ? 'header--visible' : 'header--hidden'}`} style={{display:location.pathname.split("/")[1]==="thankyou"?"none":""}}>
    {menu==="0" && <div className="overlay" onClick={closeMenu}></div>}

      <div className="header1">

        
        <div className="head1">
        <Link className='logo' to="/"><h1 >Chic'O'line</h1></Link>
        <div className="search">
                <input type="search" name="" id="" placeholder='Search for products ....' value={searchValue} onChange={(e)=>setSearchValue(e.target.value)} onKeyDown={handleKeyDown}/>
                <span class="material-symbols-outlined" onClick={searchClick}>
search
</span>
            </div>
            <div className="currency">
                <div>
                <select id="currencySelect" value={current} onChange={(e)=>changeCurrency(e.target.value)}>
    <option value="CAD">CAD</option>
    <option value="USD">USD</option>
    <option value="CHF">CHF</option>
    <option value="CZK">CZK</option>
    <option value="DKK">DKK</option>
    <option value="EUR">EUR</option>
    <option value="GBP">GBP</option>
    <option value="MXN">MXN</option>
    <option value="NOK">NOK</option>
    <option value="PLN">PLN</option>
    <option value="SEK">SEK</option>
</select>
                </div>
                <div className="logout">
                  <Link to="/orders"><span class="material-symbols-outlined">
featured_seasonal_and_gifts
</span> </Link>
                      </div>
            </div>
        </div>
        <div className="head2">
            <div className="menu">
            <ul>
                <Link to={`/collections/Men`}><li>Mens</li></Link>
                <Link to={`/collections/Women`}><li>Womens</li></Link>
                <Link to={`/collections/Kids`}><li>Kids</li></Link>
                <Link to={`/collections/Accessories`}><li>Accessories</li></Link>
                <Link to="/contact"><li>Contact</li></Link>
                <Link to="/about"><li>About</li></Link>
            </ul>
            </div>
            <div className="icons">
                <div className="like">
                <span class="material-symbols-outlined" onClick={()=>navigate("/wishlist")}>
favorite {
  wishlist && wishlist?.length>0?<p>{wishlist?.length}</p>:""
}
</span>
                </div>
                <div className="cart">
                <span class="material-symbols-outlined" onClick={cartOpen}>
shopping_cart {
  cartItems && cartItems?.length>0?<p>{cartItems?.length}</p>:""
}
</span>

                </div>
            </div>
        </div>
      </div>
      <div className="header2">
        <div className="head1">
            <div className="ham">
            <span class="material-symbols-outlined" onClick={openMenu} style={{display:menu==="0"?"none":"block"}}>
menu
</span>
<span class="material-symbols-outlined" onClick={closeMenu} style={{display:menu==="0"?"block":"none"}}>
close
</span>
<div>

</div>
<Link className='logo' to="/" onClick={closeMenu}><h1 >Chic'O'line</h1></Link>
</div>
            <div className="icons">
            <div className="search">
                <span class="material-symbols-outlined" onClick={openSearch}>
search

</span>
                </div>
                <div className="cart">
                <span class="material-symbols-outlined" onClick={cartOpen}>
shopping_cart {
  cartItems && cartItems?.length>0?<p>{cartItems?.length}</p>:""
}
</span>
                </div>
            </div>
        </div>
        
        <div className="head2" style={{left:menu}}>
<ul>
  <div className="profile">
    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt="" />
    <div className="info">
    <p className="name">keshav</p>
    <p className="orders">{JSON.parse(localStorage.getItem('orders'))?.length} orders</p>
    </div>
  </div>
<div className="pages">
<Link to="/" onClick={closeMenu}><li><span class="material-symbols-outlined">
home
</span><span>Home</span></li></Link>
<Link to={`/collections/Men`} onClick={closeMenu}><li><span class="material-symbols-outlined">
face_6
</span><span>Men</span></li></Link>   
<Link to={`/collections/Women`} onClick={closeMenu}><li><span class="material-symbols-outlined">
face
</span><span>Women</span></li></Link>   
<Link to={`/collections/Kids`} onClick={closeMenu}> <li><span class="material-symbols-outlined kid">
child_care
</span><span>Kids</span></li></Link>  
<Link to={`/collections/Accessories`} onClick={closeMenu}><li><span class="material-symbols-outlined">
dry_cleaning
</span><span>Accessories</span></li></Link>   
<li><span class="material-symbols-outlined">
attach_money
</span><span>Currency</span>
<select id="currencySelect" value={current} onChange={(e)=>changeCurrency(e.target.value)}>
    <option value="CAD">CAD</option>
    <option value="USD">USD</option>
    <option value="CHF">CHF</option>
    <option value="CZK">CZK</option>
    <option value="DKK">DKK</option>
    <option value="EUR">EUR</option>
    <option value="GBP">GBP</option>
    <option value="MXN">MXN</option>
    <option value="NOK">NOK</option>
    <option value="PLN">PLN</option>
    <option value="SEK">SEK</option>
</select>
</li>
<Link to="/orders" onClick={closeMenu}>
<li><span class="material-symbols-outlined">
featured_seasonal_and_gifts
</span><span>Orders</span></li>
</Link>
<li><span class="material-symbols-outlined">
favorite 

</span><span>Wishlist</span></li>
</div>
</ul>
        </div>
      </div>
    </div>
          <div className='cart-draw' style={{right:carts===false?"-100%":"0"}}>

          <div className="carts" >
                  <span className="material-symbols-outlined back-icon" onClick={cartHide1}>
          arrow_right_alt
          </span>
          {
            cartItems && cartItems?.length>0?
            <div>
          <p className="cart-name"><span>CART</span> <span class="material-symbols-outlined">
          shopping_cart
          </span></p>
          
          <div className="items">
            {
              cartItems && cartItems.map((item,index)=>{
                return <div className="item" key={index}>
                <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt={item?.product?.title} />
                <div className="info">
                  <p className="name">{item?.product?.title}</p>
                  <p className="color">Color: {item?.color}</p>
                  <p className="size">Size: {item?.size}</p>
                  <div className="qty">
                  <span onClick={() => handleQuantityChange(item?.productId, item?.color, item?.size, 'decrement')}>-</span>
                    <p>{item?.quantity}</p>
                    <span onClick={() => handleQuantityChange(item?.productId, item?.color, item?.size, 'increment')}>+</span>
          
                  </div>
                </div>
                <div className="price">
                <span class="material-symbols-outlined cancel" onClick={()=>removeFromCartAndUpdate(item.productId, item.color, item.size)}>
            close
            </span>
            <p className="pricing">
  <span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} />
  <span className='net'>{item?.price}</span>
</p>                 </div>
              </div>
            
              })
            }
          
            
            <div className="checkout">
              <div className="total">
                  <p>Total</p>
                  <p className="pricing">
  <span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} />
  <span className='net'>{totalAmount}</span>
</p> 
              </div>
              <Link to="/checkout" onClick={cartHide}><button>CHECKOUT</button></Link>
            </div>
          </div>
          </div>
          :
          <div className="no-data">
          <img src="https://res.cloudinary.com/dqh6bd766/image/upload/v1719827762/cart_i2htxa.png" alt="" />
          <p className='no-data-para'>Hey it feels so light!</p>
          <p className='para'>There is nothing in your cart. Let's add some items.</p>
          <button>Continue Shopping</button>
          
          </div>
          }
          
          
          
                  </div>
                </div>

                <div className="mobile-search" style={{top:searchOpen}}>
          <div className='main-search'>
          <div className="search-bar">
          <input type="search" name="" id="" placeholder='Search for Products...' value={searchValue} onChange={(e)=>setSearchValue(e.target.value)} onKeyDown={handleKeyDown}/>
          <span class="material-symbols-outlined" onClick={searchClick}>
search

</span>
          </div>
          <span class="material-symbols-outlined icos" onClick={closeSearch}>
close
</span>
          </div>
        </div>
                </>
  )
}

export default Header
