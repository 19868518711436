import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './app/store'
import { Toaster } from 'react-hot-toast';
import { CartProvider } from './context/CartContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
<CartProvider>
    <App />
    <Toaster 
    position='top-right'
    />
    </CartProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
