import React, { useEffect, useState,useContext } from 'react'
import './sproduct.css'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getAProduct} from '../../features/products/productSlice'
import loading from '../images/loading.gif'
import toast from 'react-hot-toast'
import { CartContext } from '../../context/CartContext';
import {
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
  Magnifier
} from "react-image-magnifiers";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
 
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const Sproduct = () => {
  const { addToCarts,goToCart,cartLength,usdCurrency } = useContext(CartContext);
    const [value, setValue] = React.useState(0);
    const [color,setColor]=useState("")
    const [size,setSize]=useState("")
    const [quantity,setQuantity]=useState(1)
    const [sold,setSold]=useState("none")
    const [btndisable,setBtnDisable]=useState(false)
    const [pQuantity,setPQuantity]=useState(null)
    const [mainimage,setMainimage]=useState("")
    const [alreadyAdded,setAlreadyAdded]=useState(false)
    const location=useLocation()
    const dispatch=useDispatch()
    const getProductHandle = location.pathname.split("/")[2];
    const productState=useSelector((state)=>state?.product?.getSingleProduct)
    useEffect(()=>{
  dispatch(getAProduct(getProductHandle))
    },[dispatch,getProductHandle])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const element = document.scrollingElement || document.documentElement;
    element.style.scrollBehavior = 'auto'; // Disable smooth scrolling
    element.scrollTop = 0; // Scroll to the top instantly
    element.style.scrollBehavior = 'auto';
  
  }, [location]);

useEffect(()=>{
  setMainimage(productState?.images[0]?.url)
},[productState])
  const findVariant = (color, size) => {
    return productState?.variants.find(variant => variant.color === color && variant.size === size);
  };
  useEffect(()=>{
    const matchingVariant = findVariant(color, size);
    setPQuantity(matchingVariant?.quantity) 
    if (matchingVariant?.quantity===0) {
        setBtnDisable(true)
        setSold("block")
         
    }
    else{
      setBtnDisable(false)
      setSold("none")

    }
  },[color,size])
  useEffect(()=>{
    window.fbq('track', 'ViewContent', {
      content_name: `${productState?.title}`,
      content_category:`${productState?.category}`,
      content_ids: `${productState?._id}`,
      content_type: 'product',
      value:productState?.price,
      currency: usdCurrency?.code
     });
  },[productState])
  useEffect(() => {
    if (productState?.variants) {
      const firstAvailableVariant = productState?.variants?.find(variant => variant.quantity > 0);
      if (firstAvailableVariant) {
        setColor(firstAvailableVariant.color);
        setSize(firstAvailableVariant.size);
      }
      if(!firstAvailableVariant){
        setBtnDisable(true) 
      }

      const totalQuantity = productState?.variants?.reduce((total, item) => total + item.quantity, 0);
      if (totalQuantity === 0) {
        setSold("block");
      } else {
        setSold("none");
      }
    }
  }, [productState?.variants]);
const [options,setOptions]=useState(null)
useEffect(()=>{
  const opt = [];
  for (let i = 1; i <= pQuantity; i++) {
    opt.push(<option key={i} value={i}>{i}</option>);
  }
setOptions(opt)
},[pQuantity])
const cart=JSON.parse(localStorage.getItem('cart'))
useEffect(() => {
  if (!color || !size) {
    // If color or size is not selected, set alreadyAdded to false
    setAlreadyAdded(false);
    return;
  }

  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const matchingCartItem = cart.find(item => {
    return item.product._id === productState?._id && item?.color === color && item?.size === size;
  });

  if (matchingCartItem) {
    // If a matching cart item is found, set alreadyAdded to true
    setAlreadyAdded(true);
  } else {
    // If no matching cart item is found, set alreadyAdded to false
    setAlreadyAdded(false);
  }
}, [color, size,getProductHandle,cartLength]);
const addProductToCartLocalStorage = (product) => {
const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
const updatedCart = [...existingCart, product];
localStorage.setItem("cart", JSON.stringify(updatedCart));
};
const addToCart=async(data)=>{
if(color===null){
 return false
}
if(size===null){
 return false
}
else{
 addProductToCartLocalStorage({productId:data,color,quantity,price:parseInt(productState?.price/usdCurrency?.value),size,product:productState})
// setAlreadyAdded(true)
window.fbq('track', 'AddToCart', {
  content_name:`${productState?.title}`,
  content_category:`${productState?.category}`,
  content_ids:`${productState?._id}`,
  content_type: 'product',
  value:`${productState?.price}`,
  currency: usdCurrency?.code
});
addToCarts(cart)
toast.success("Cart Updated")
}


}

const buyNow=async(data)=>{
  if(color===null){
   return false
  }
  if(size===null){
   return false
  }
  else{
   addProductToCartLocalStorage({productId:data,color,quantity,price:parseInt(productState?.price/usdCurrency?.value),size,product:productState})
  // setAlreadyAdded(true)
  window.fbq('track', 'InitiateCheckout', {
    content_name:`${productState?.title}`,
    content_category:`${productState?.category}`,
    content_ids:`${productState?._id}`,
    content_type: 'product',
    value:`${productState?.price}`,
    currency: usdCurrency?.code
});
  addToCarts(cart)
 navigate("/checkout")
  }
  
  
  }


  useEffect(() => {
    if (productState?.metaTitle4!=="") {
        document.title = productState?.metaTitle4;
    }
    else{
      document.title =productState?.title;
    }

}, [productState?.metaTitle4,productState?.title]);
useEffect(() => {
  if (productState?.metaDesc4!=="") {
      document.querySelector('meta[name="description"]').setAttribute('content',productState?.metaDesc4);
  }
  else{
    document.querySelector('meta[name="description"]').setAttribute('content',document.createElement('div').innerHTML = productState?.description );
  }

}, [productState?.metaDesc4,productState?.description]);

const navigate=useNavigate()
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
  return (
    <>
    
    <div className='singlep'>
      {
        productState && productState?
        <div>
      <div className="single-product">
      
        <div className="left">
        <div className="thumbs">
        <span class="material-symbols-outlined">
arrow_drop_up
</span>
                <div className="thumb">
                  {
                    productState?.images?.map((item,index)=>{
                      return <img src={modifyCloudinaryUrl(item?.url)} alt="" key={index} onClick={()=>setMainimage(item?.url)}/>
                    })
                  }
                    

                </div>
                <span class="material-symbols-outlined">
arrow_drop_down
</span>
            </div>
            <div className="main-img">
            <Magnifier
  imageSrc={modifyCloudinaryUrl(mainimage)}
  imageAlt="Example"
  dragToMove={false}
  largeImageSrc={mainimage} // Optional
  mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
  touchActivation={TOUCH_ACTIVATION.TAP} // Optional
/>
                <img src={modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1719568711/banner_354_olgvcj.png")} alt="" className="tag" />
            </div>
           
        </div>
        <div className="right">
            <p className="brand">{productState?.collectionName}</p>
            <h1 className="title">{productState?.title}</h1>
            <Stack spacing={1} className='star'>
      <Rating name="size-small" value={5} size="large" readOnly/>
    </Stack> 
    <p className="price">
  <span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} />
  <span className='net'>{parseInt(productState?.price / usdCurrency?.value)}</span>
</p>            
            <div className="color">
                <p>AVAILABLE COLORS</p>
                <ul>
                    {
  productState?.variants?.filter((item, index, arr) => arr.findIndex(i => i.color === item.color) === index)
                .map((item, index) => <li onClick={() => (setColor(item.color),setQuantity(1))} key={index} style={{border:item.color===color?'2px solid black':'1px solid grey',color:item.size===size?'black':'rgb(122, 122, 122)'}}>{item.color}</li>)
}
                </ul>
            </div>
            <div className="color">
                <p>AVAILABLE SIZES <span>Size Chart</span></p>
                <ul>
                {productState?.variants
      .filter(variant => variant.color === color) // Filter variants based on selected color
      .map((variant, index) => (
        <li
          key={index}
          onClick={() => {
            if (variant.quantity > 0) {
              setSize(variant.size);
            }
          }}
          style={{
            border: variant.size === size ? '2px solid black' : '1px solid grey',
            color: variant.size === size ? 'black' : 'rgb(122, 122, 122)',
            opacity:variant.quantity === 0 ? 0.5 : 1,
            textDecoration: variant.quantity === 0 ? 'line-through' : 'none' ,
            textDecorationThickness: variant.quantity === 0 ? '1px' : 'auto',
            pointerEvents: variant.quantity === 0 ? 'none' : 'auto', // Disable pointer events if quantity is 0
            // Make the line bold if quantity is 0
            // Apply line-through if quantity is 0
          }}
        >
          {variant.size}
        </li>
      ))}

                </ul>
            </div>
            <div className="size">
            <div className="qty">
                    <select name="" id="" value={quantity} onChange={(e)=>setQuantity(e.target.value)}>
                        <option value="">QTY</option>
                        {options?.map((option,index) => (
        <option key={index} value={index+1}>
          {option}
        </option>
      ))}

                    </select>
                </div>
            </div>

            <div className="buy-btns">
              {
                alreadyAdded?
                <button className="cart" onClick={()=>goToCart(true)}><span class="material-symbols-outlined">shopping_cart</span>Go to Cart</button>
:
<button className="cart" onClick={()=>addToCart(productState?._id)}><span class="material-symbols-outlined">shopping_cart</span>Add to Cart</button>

              }
              {
                alreadyAdded?
                <button className="cart" onClick={()=>navigate("/checkout")}><span class="material-symbols-outlined">shopping_cart_checkout</span>Complete Purchase</button>
:
<button className="cart" onClick={()=>buyNow(productState?._id)}><span class="material-symbols-outlined">shopping_cart_checkout</span>Buy It Now</button>

              }
            </div>
        </div>
      </div>
      <div className="desc">
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='tabs'>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
          <Tab label="Description" {...a11yProps(0)}/>
          <Tab label="Privacy Policy" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <p dangerouslySetInnerHTML={{ __html: productState?.description }}></p>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam alias ratione blanditiis laboriosam ea quibusdam voluptate vitae ad dolorem quae odit illo sed consequuntur voluptatum illum nihil, doloribus minima laudantium quidem! Mollitia nulla praesentium neque modi sapiente sed quasi eveniet eligendi doloremque fugit voluptate, ipsa voluptatibus architecto est quisquam libero, quae totam magnam asperiores sit. Provident officia dolorem saepe nam. Autem minima dolore quos dolores ex distinctio dolorum accusantium itaque, nemo laudantium exercitationem voluptatibus facere, praesentium dolorem sequi quaerat quo consequuntur sunt sit mollitia! Eligendi itaque, accusamus ad earum ab pariatur alias cum laudantium, architecto porro vitae perferendis fuga quidem.
      </CustomTabPanel>
    </Box>
      </div>
      </div>
      :
<div className="loader">
          <div className="loading">
          <img src={loading} alt="" />

          </div>
        </div>
      }
    
      
    </div>
    </>

  )
}

export default Sproduct
