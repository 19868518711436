import React, { useState } from 'react'
import './tracking.css'
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const Tracking = () => {
    const [data,setData]=useState("")
    const options = {
        method: 'GET',
        url: 'https://api-test.dhl.com/track/shipments',
        params: {trackingNumber: '874567883831'},
        headers: {'DHL-API-Key': 'demo-key'}
      };
      
      axios.request(options).then(function (response) {
        setData(response.data)
      }).catch(function (error) {
        console.error(error);
      });

      const location=useLocation()
      const trackingId=location.pathname.split("/")[2]

      const steps = [
        {
          label: 'Select campaign settings',
          description: `For each ad campaign that you create, you can control how much
                    you're willing to spend on clicks and conversions, which networks
                    and geographical locations you want your ads to show on, and more.`,
        },
        {
          label: 'Create an ad group',
          description:
            'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
          label: 'Create an ad',
          description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
      ];
      
      

      



  return (
    <div className='trackings'>
      <div className="track">
        {/* <div className="no-detail">
            <p className="number">Sorry, We don't have any information for tracking number</p>
            <p className="tracks">{trackingId}</p>
            <p className="contact">Please check tracking number</p>
            <p>Or</p>
            <p>Try to contact <a href="mailto:chicoline@voguemine.com">chicoline@voguemine.com</a></p>
        </div> */}
<div className="details">
    <div className="source">
        <p className="s">{data[0]?.origin?.address?.addressLocality}</p>
        <p className="path">
        <p className="mark"></p>
        </p>
        <p className="dest">{data[0]?.destination?.address?.addressLocality}</p>
    </div>
    <div className="info">
        <div className="left">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/022/532/256/small_2x/3d-rendering-correct-icon-minimal-png.png" alt="" />
            <p className="msg">Order Confirmed</p>
            <p className="desc">We have recieved your order successfully.</p>
            <p className="time">12/07/2024</p>
            <p className="time">13:55:34 AM</p>
        </div>
        <div className="right">
            <div className="id">
                <p>TrackingId</p>
                <p>{trackingId}</p>
            </div>
            <div className="address">
                <p className='heads'>Source</p>
                <p>Noida</p>
                <p>Uttar Pradesh</p>
                <p>201301</p>
            </div>
            <div className="address">
                <p className='heads'>Destination</p>
                <p>Noida</p>
                <p>Uttar Pradesh</p>
                <p>201301</p>
            </div>
            <div className="live">
            <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
            </div>
        </div>
    </div>
</div>
      </div>
    </div>
  )
}

export default Tracking
