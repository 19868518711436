import React,{useContext} from 'react'
import './orders.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom'
import a2 from '../images/a2.jpg'
import { CartContext } from '../../context/CartContext';

const Orders = () => {
  const {usdCurrency}=useContext(CartContext)
    const orders=JSON.parse(localStorage.getItem("orders"))
  return (
    <div className='orders'>
          <div className="path">
    <p className="paths">
      <Link to="/"><span>Home</span></Link>
    <ChevronRightIcon/>
      <span>Orders</span>
    </p>
  </div>
      
      <div className="order-list">
        {
            orders?.map((item,index)=>{
                return <Link key={index} to={`/orders/${index}`}>
                <div className="order">
                            <img src={item?.orderItems[0]?.product?.images[0]?.url} alt="" />
                            <p>Order No.: #{item?.orderNumber}</p>
                            <div className="info">
                                <ul>
                                    <li>Items</li>
                                    <li>Subtotal</li>
                                    <li>Discount</li>
                                    <li>Total</li>
                                </ul>
                                <ul>
                                    <li>{item?.orderItems?.length}</li>
                                    <li><span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {item?.totalPrice}</li>
                                    <li>- <span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {item?.discount}</li>
                                    <li><span dangerouslySetInnerHTML={{__html:usdCurrency?.htmlCode}}></span> {item?.finalAmount}</li>
                                </ul>
                            </div>
                        </div>
                </Link>
            })
        }


        
      </div>
    </div>
  )
}

export default Orders
