import React,{useEffect, useState,useContext} from 'react'
import './products.css'
import Product from '../../components/product/Product'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ScrollCarousel from 'scroll-carousel-react';
import {useLocation,Link,useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getinnerCollection} from '../../features/collection/collectionSlice'
import { getProducts,resetState } from '../../features/products/productSlice';
import loadingImg from '../images/loading.gif'
import TuneIcon from '@mui/icons-material/Tune';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from '../../context/CartContext';

const Search = () => {
const {usdCurrency}=useContext(CartContext)
const location=useLocation()
  const queryParams = new URLSearchParams(location.search);
  let page = parseInt(queryParams.get('page')) || 1;  
  const [filterOpen,setFilterOpen]=useState("-100%")
  const [size,setSize]=useState("")
  const [color,setColor]=useState("")
  const [brand,setBrand]=useState("")
  const [menu,setMenu]=useState("size")
  const [sort,setSort]=useState("-createdAt")
  const [sortOpen,setSortOpen]=useState("-100%")
  const colorMenu=["Red","Yellow","Green","Orange","White","Black","Grey","Blue","Purple","Pink","Brown"]
  const brandMenu = [
    "Amiri",
    "Armani",
    "Balenciaga",
    "Bally",
    "Balmain",
    "Bottega Veneta",
    "Burberry",
    "Calvin Klein",
    "Celine",
    "Chanel",
    "Christian Louboutin",
    "Coach",
    "Dior",
    "DKNY",
    "Dolce & Gabbana",
    "Fendi",
    "Fred Perry",
    "Givenchy",
    "Gucci",
    "Hermes",
    "Hugo Boss",
    "Karl",
    "Kenzo",
    "Lanvin",
    "Louis Vuitton",
    "Michael Kors",
    "Moncler",
    "Moschino",
    "Off-White",
    "Prada",
    "Ralph Lauren",
    "Salvatore Ferragamo",
    "Stefano Ricci",
    "Tod's",
    "Tom Ford",
    "Tory Burch",
    "Valentino",
    "Versace",
    "Victoria's Secret",
    "Yves Saint Laurent (YSL)",
    "Zegna"
];  
const navigate=useNavigate()
const [collectionName,setCollectionName]=useState("")
const search = JSON.parse(localStorage.getItem("search"))
useEffect(() => {
  const search = JSON.parse(localStorage.getItem('search'));
  if (search && location.pathname === '/products') {
    setCollectionName(search.mysearch);
    // Update the URL with search.mysearch
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('search', search.mysearch);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }
}, [search && search.mysearch]);
useEffect(()=>{
    if(location?.pathname!=="/products"){
      localStorage.removeItem("search")
    }
  },[location])
  const dispatch=useDispatch();
const productState=useSelector((state)=>state?.product?.prdt)
useEffect(() => {
  dispatch(resetState());
}, [dispatch]);

// useEffect(() => {
//       dispatch(getProducts({ page: page, limit: 24, searchValue:collectionName,size,color,brand,sort }));
// }, [collectionName,size,color,brand,sort,search?.mysearch]);
useEffect(() => {
  const element = document.scrollingElement || document.documentElement;
  element.style.scrollBehavior = 'auto'; // Disable smooth scrolling
  element.scrollTop = 0; // Scroll to the top instantly
  element.style.scrollBehavior = 'auto';

}, [location,page]);
const uniqueSizes = new Set();

  productState?.products?.forEach(product => {
    product.variants.forEach(variant => {
      uniqueSizes.add(variant.size);
    });
  });

  // Convert the Set to an array
  const uniqueSizesArray = Array.from(uniqueSizes);


const handlePageChange=(e,v)=>{
  updateURL(v)
}

const toggleSize=(sizes)=>{
  if(size===sizes){
    setSize("")
  }
  else{
    setSize(sizes)
  }
}
const toggleColor=(colors)=>{
  if(color===colors){
    setColor("")
  }
  else{
    setColor(colors)
  }
}
const toggleBrand=(brands)=>{
  if(brand===brands){
    setBrand("")
  }
  else{
    setBrand(brands)

  }
}
const toggleMenu=(menu)=>{
  setMenu(menu)
}
const toggleSort=(sort)=>{
  setSort(sort)
  setSortOpen("-100%")
}

const isSelectedSize = (sizes) => size===sizes ? 'selected' : '';
const isSelectedColor = (colors) => color===colors ? 'selected' : '';
const iseSelectedBrand = (brands) => brand===brands ? 'selected' : '';
const iseSelectedMenu = (menus) => menu===menus ? 'selected' : '';
const isSelectedSort = (sorts) => sort===sorts ? 'selected' : '';

const openFilter=()=>{
  setFilterOpen("0")
}
const closeFilters=()=>{
  setFilterOpen("-100%")
}
const openSorting=()=>{
  setSortOpen("0")
}
const closeSorting=()=>{
  setSortOpen("-100%")
}
const closeMenu=()=>{
  setFilterOpen("-100%")
  setSortOpen("-100%")
}
const [load,setLoad]=useState(true)
useEffect(()=>{
if(productState?.products?.length>0){
  setTimeout(()=>{
setLoad(false)
  },2500)
}
else{
    setLoad(true)
}
},[productState,collectionName,search?.mysearch])

const updateURL = (sizeNumber) => {
    setLoad(true)
  const searchParams = new URLSearchParams();

  // Add page parameter
  searchParams.set('page', sizeNumber);

  // Add filter parameters
  if (size!=="") {
    searchParams.set('size', size);
  }
  if (color!=="") {
    searchParams.set('color', color);
  }
  if (brand!=="") {
    searchParams.set('brand', brand);
  }

  navigate(`${location.pathname}?${searchParams.toString()}`);
};
useEffect(()=>{
  const filter = {};

  // Add selected sizes to the filter object
  if (size!=="") {
    filter.size = size;
  }

  // Add selected colors to the filter object
  if (color!=="") {
    filter.color = color;
  }

  // Add selected brands to the filter object
  if (brand!=="") {
    filter.brand = brand;
  }
      dispatch(getProducts({sort,limit:24,page,searchValue:search?.mysearch,...filter}))
  setLoad(true)
},[page,collectionName,sort,search?.mysearch])

const applyFilter = () => {
  const filter = {};

  // Add selected sizes to the filter object
  if (size!=="") {
    filter.size = size;
  }

  // Add selected colors to the filter object
  if (color!=="") {
    filter.color = color;
  }

  // Add selected brands to the filter object
  if (brand!=="") {
    filter.brand = brand;
  }

  const searchParams = new URLSearchParams(location.search);
  if (filter.size) {
    searchParams.set('size', filter.size);
  } else {
    searchParams.delete('size');
  }
  if (filter.color) {
    searchParams.set('color', filter.color);
  } else {
    searchParams.delete('color');
  }
  if (filter.brand) {
    searchParams.set('brand', filter.brand);
  } else {
    searchParams.delete('brand');
  }

  navigate(`${location.pathname}?${searchParams.toString()}`);

    dispatch(getProducts({ sort, limit:24, page, searchValue:collectionName, ...filter }));

  setLoad(true)

  closeFilters()
};

const clearFilter=()=>{
  setSize("")
  setColor("")
  setBrand("")
    dispatch(getProducts({ sort: sort, limit:24, page, searchValue:collectionName }));
setLoad(true)
  navigate(location.pathname);  // Clears all query parameters
  closeFilters()
}

useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const sizesParam = queryParams.get('size');
  const colorsParam = queryParams.get('color');
  const brandsParam = queryParams.get('brand');

  if (sizesParam) {
    setSize(sizesParam);
  } else {
    setSize("");
  }

  if (colorsParam) {
    setColor(colorsParam);
  } else {
    setColor("");
  }

  if (brandsParam) {
    setBrand(brandsParam);
  } else {
    setBrand("");
  }
}, [location.search]);
console.log(productState)

  return (
    <div className='main-products'>
      {
        load===false?
<div className='mobile'>
  {(filterOpen==="0" || sortOpen==="0")?<div className='overlay1' onClick={closeMenu}></div>:""}
  <div className="path">
    <p className="paths">
      <Link to="/"><span>Home</span></Link>
    <ChevronRightIcon/>
      <span>{collectionName}</span>
    </p>
  </div>
  <div className="filter">
    <p className="option" onClick={openFilter}>
<TuneIcon/>
<span>Filter</span>
    </p>
    <p className="option" onClick={openSorting}>
<SortByAlphaIcon/>
<span>Sort</span>
    </p>
    <div className="filter-box" style={{bottom: filterOpen}}>
  <div className="headers">
    <p className="heads">FILTER</p>
    <span onClick={closeFilters}><CloseIcon/></span>
  </div>
  <div className="filter-body">
    <div className="left">
        <ul>
          <li onClick={()=>toggleMenu("color")} className={iseSelectedMenu("color")}>Color</li>
          <li onClick={()=>toggleMenu("size")} className={iseSelectedMenu("size")}>Size</li>
          <li onClick={()=>toggleMenu("brand")} className={iseSelectedMenu("brand")}>Brand</li>
        </ul>
    </div>
    <div className="right">
      {
        menu==="size"?
        <div className="content">
<p>Size</p>
      <ul>
      {uniqueSizesArray.map((size, index) => (
        <li key={index} onClick={()=>toggleSize(size)} className={isSelectedSize(size)}>{size}</li>
      ))}
      </ul>
</div>
:
menu==="color"?
<div className="content">
<p>Color</p>
      <ul>
      {colorMenu.map((color, index) => (
        <li key={index} onClick={()=>toggleColor(color)} className={isSelectedColor(color)}>{color}</li>
      ))}
      </ul>
</div>
:
<div className="content">
<p>Brand</p>
      <ul>
      {brandMenu.map((brand, index) => (
        <li key={index} onClick={()=>toggleBrand(brand)} className={iseSelectedBrand(brand)}>{brand}</li>
      ))}
      </ul>
</div>
      }

    </div>
  </div>
  <div className="filter-foot">
    <p className="count">
      28 Products
    </p>
    <div className="right">
      <button onClick={clearFilter}>Clear Filters</button>
      <button onClick={applyFilter}>Done</button>
    </div>
  </div>
</div>
<div className="sort-box" style={{bottom:sortOpen}}>
<div className="headers">
    <p className="heads">SORT</p>
    <span onClick={closeSorting}><CloseIcon/></span>
  </div>
  <div className="sort-body">
    <p onClick={()=>toggleSort("-createdAt")} className={isSelectedSort("-createdAt")}>New to Old</p>
    <p onClick={()=>toggleSort("createdAt")} className={isSelectedSort("createdAt")}>Old to New</p>
    <p onClick={()=>toggleSort("price")} className={isSelectedSort("price")}>Price (Low to High)</p>
    <p onClick={()=>toggleSort("-price")} className={isSelectedSort("-price")}>Price (High to Low)</p>
    <p onClick={()=>toggleSort("title")} className={isSelectedSort("title")}>Title (A to Z)</p>
    <p onClick={()=>toggleSort("-title")} className={isSelectedSort("-title")}>Title (Z to A)</p>
  </div>
</div>
  </div>
  <div className="products-section">
    <div className="products-list">
        {
          productState?.products?.map((item,index)=>{
            return <Product info={item} current={usdCurrency}/>
          })
        }
    </div>
    <div className="pagination">
    <Stack spacing={2}>
      <Pagination count={productState?.pagination?.totalPages} page={page} variant="outlined" shape="rounded" onChange={handlePageChange}/>
    </Stack>
    </div>
  </div>
</div>
        :
        <div className="loader">
          <div className="loading">
          <img src={loadingImg} alt="" />

          </div>
        </div>
      
      }
      
        
        
    </div>
  )
}

export default Search
