import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import {collectionService} from './colletionService'


export const getAllCollection=createAsyncThunk("collection/get",async(thunkAPI)=>{
    try{
        return await collectionService.getCollection()
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const getsingleCollection=createAsyncThunk("collection/get-single",async(data,thunkAPI)=>{
    try{
        return await collectionService.getSingleCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const getinnerCollection=createAsyncThunk("collection/get-inner",async(data,thunkAPI)=>{
    try{
        return await collectionService.getInnerCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})

const collectionState={
    collection:"",
    isError:false,
    isLoading:false,
    isSuccess:false,
    message:""
}


export const collectionSlice=createSlice({
    name:"collection",
    initialState:collectionState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getAllCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.collection=action.payload;
        }).addCase(getAllCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(getinnerCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getinnerCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.innercollection=action.payload;
        }).addCase(getinnerCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(getsingleCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getsingleCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.singlecollection=action.payload;
        }).addCase(getsingleCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
    }
})
export default collectionSlice.reducer