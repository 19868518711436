import React from 'react'
import './about.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import a1 from '../images/a1.mp4'
import a2 from '../images/a2.jpg'
import a3 from '../images/a3.jpg'
import a4 from '../images/a4.jpg'

const About = () => {
  return (
    <div className='about'>
       <div className="about-head">
       <video className='videoTag' playsInline autoPlay loop muted>
    <source src={a1} type='video/mp4' className='video'/>
</video>

<div className="content">
  <h1>About Us</h1>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic inventore quos enim officiis velit doloremque vitae vero, illum nobis nemo rerum? Ducimus accusamus, at iure ullam officia rem harum voluptatem enim minus nisi deleniti eaque unde voluptate voluptatum. Ratione cumque voluptas veritatis vero ipsa fugiat, cupiditate fuga unde culpa odio?</p>
  {/* <div className="icons">
      <FacebookIcon className='icon'/>
      <InstagramIcon className='icon'/>
  </div> */}
</div>
       </div>
       <div className="about-body">
        <div className="bodies">
          <img src={a2} alt="" />
          <div className="info">
            <p className='heads'>Our Products</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, dolore alias non dolor labore maiores?</p>
          </div>
        </div>
        <div className="bodies">
        <img src={a3} alt="" />

        <div className="info">
            <p className='heads'>Our Packing</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, dolore alias non dolor labore maiores?</p>
          </div>
        </div>
        <div className="bodies">
          <img src={a4} alt="" />
          <div className="info">
            <p className='heads'>Our Warehouse</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, dolore alias non dolor labore maiores?</p>
          </div>
        </div>
       </div>
    </div>
  )
}

export default About
