import React,{useContext, useEffect, useState} from 'react'
import './wishlist.css'
import {Link, useNavigate} from 'react-router-dom'
import { CartContext } from '../../context/CartContext'
import CloseIcon from '@mui/icons-material/Close';
const Wishlist = () => {
  const {usdCurrency,removeToWishlist,wishLength} =useContext(CartContext)
  const [wishlist,setWishlist]=useState()
  const navigate=useNavigate()
useEffect(()=>{
setWishlist(JSON.parse(localStorage.getItem("wishlist")))
},[])
useEffect(()=>{
  setWishlist(JSON.parse(localStorage.getItem("wishlist")))
  },[wishLength])
  const removeFromWish=(data)=>{
    const updatedWishlist=wishlist?.filter(item=>{
      return !(item?._id===data)
    }
    )
    
    localStorage.setItem("wishlist",JSON.stringify(updatedWishlist))
    setWishlist(updatedWishlist)
    removeToWishlist(wishlist)
    
    }


  return (
    <div className='wishlist'>
      {
        wishlist && wishlist?.length>0?
<div className="items">
            {
              wishlist && wishlist?.map((item,index)=>{
                return <div className="wish-card">
                  <Link to={`/product/${item?.handle}`}><img src={item?.images[0]?.url} alt="" /></Link>
                  <p className="name">{item?.title}</p>
                  <p className="price">
  <span dangerouslySetInnerHTML={{ __html: usdCurrency?.htmlCode }} />
  <span className='net'>{parseInt(item?.price / usdCurrency?.value)}</span>
</p>
<span onClick={(e)=>removeFromWish(item?._id)}><CloseIcon className='cancel'/></span>

                </div>
              })
            }
            </div>
            :
<div className="no-data">
            <img src="https://cdn-icons-png.flaticon.com/512/4379/4379680.png" alt="" />
            <h1>NO DATA</h1>
            <p>The are no products to show</p>
            <button onClick={navigate("/")}>SHOP NOW</button>
        </div>
      }
        
        
    </div>
  )
}

export default Wishlist
